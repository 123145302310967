<template>
    <v-card>
        <v-card-title>
            Reportistica Periodica
            <Confirm message="Aggiungi allegato" width="400px" @confirm="uploadAllegato"
                     :enabled="enabled">
                <template #activator="{on, attrs}">
                    <v-btn v-if="$auth('amministratore')" color="success" v-bind="attrs" v-on="on"
                           class="ml-auto">
                        Carica file
                    </v-btn>
                </template>
                <template #default>
                    <v-select :items="tipologia" v-model="args.tipologia"
                              label="Tipologia"></v-select>
                    <v-file-input v-model="args.allegato" label="Allegato" required></v-file-input>
                    <DatePicker label="Data caricamento" v-model="args.data_upload"></DatePicker>
                </template>
            </Confirm>
        </v-card-title>
        <v-data-table
            :headers="[
                {value:'nomefile', text: 'Nome file'},
                {value:'s_tipologia', text: 'Tipologia'},
                {value:'data_upload', text: 'Data Caricamento'},
                {value:'stato', text: 'Stato'},
                {value:'actions', align: 'right'}]"
            :items="allegati"
        >
            <template #item.data_upload="{item}">
                <LocalDate :date="item.data_upload"></LocalDate>
            </template>
            <template #item.actions="{item}">
                <v-btn  v-if="$auth(['amministratore', 'consip'])" small color="primary" @click="download(item.id)" class="mr-2">
                    Download
                </v-btn>
                <Confirm message="Approvazione?" width="450px" :item="item" @confirm="approvazione">
                    <template #activator="{on, attrs}">
                        <v-btn v-if="$auth(['amministratore', 'consip']) && !item.stato" small color="success"
                               v-bind="attrs"
                               v-on="on"
                               class="mr-2">
                            Approva
                        </v-btn>
                    </template>
                </Confirm>
                <Confirm message="Rifiuto?" width="450px" :item="item" @confirm="rifiuto">
                    <template #activator="{on, attrs}">
                        <v-btn v-if="$auth(['amministratore', 'consip']) && !item.stato" small color="error"
                               v-bind="attrs" v-on="on" class="mr-2">
                            Rifiuta
                        </v-btn>
                    </template>
                </Confirm>
                <Confirm message="Confermi la cancellazione del file?" width="450px" :item="item"
                         @confirm="cancella">
                    <template #activator="{on, attrs}">
                        <v-btn v-if="$auth('amministratore')" small color="error" v-bind="attrs" v-on="on">
                            Cancella
                        </v-btn>
                    </template>
                </Confirm>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import LocalDate from "@/components/LocalDate";
import Confirm from "@/components/Confirm";
import DatePicker from "@/components/DatePicker";
import {mapState} from "vuex";

export default {
    components: {
        LocalDate,
        Confirm,
        DatePicker,
    },
    data: () => ({
        convenzione: {},
        allegati: [],
        args: {}
    }),
    mounted() {
        this.loadAllegati();
    },
    computed: {
        ...mapState(['dataset']),
        tipologia() {
            return this.dataset['tipologia_report_periodica'];
        },
        enabled() {
            return !!this.args.tipologia && !!this.args.allegato && !!this.args.data_upload;
        }
    },
    methods: {
        async loadAllegati() {
            const res = await this.$http.get(`/audit/report_periodica/`,);
            if (res.ok) {
                this.allegati = res.result.data;
            }
        },

        async uploadAllegato() {
            const res = await this.$http.postForm(`/audit/report_periodica/upload`, this.args);
            if (res.ok) {
                await this.loadAllegati();
            }
            this.args.allegato = null;
            this.args.tipologia = null;
            this.args.data_upload = null;
            this.notify('Caricamento Avvenuto con successo');
        },
        async cancella(item) {
            await this.$http.delete(`/audit/report_periodica/${item.id}/`);
            await this.loadAllegati();
        },
        async approvazione(item) {
            await this.$http.post(`/audit/report_periodica/${item.id}/approvazione/`);
            await this.loadAllegati();
        },
        async rifiuto(item) {
            await this.$http.post(`/audit/report_periodica/${item.id}/rifiuto/`);
            await this.loadAllegati();
        },
        download(id) {
            this.$http.downloadFile(`/audit/report_periodica/${id}/download/`);
        }
    }
}
</script>
